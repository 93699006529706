import React, { Component } from "react";
import { auth } from "../../services/firebase";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";

export default class ListingExtraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <IndexNavbar />
        <FormHeader title="Listing Extraction Tool" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <small>
                      Upload a CSV below to start a listing data scrape. The CSV
                      must have either an 'ASIN' (Amazon) or 'PRODID' (Walmart)
                      column.
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form
                    action="/api/upload/uploadListingExtractionFile"
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="client"
                        name="client"
                        placeholder="Customer Name"
                        required
                      ></Input>
                    </InputGroup>
                    <br></br>
                    {/* <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        placeholder={email}
                      ></Input>
                    </InputGroup>
                    <br></br> */}
                    {/* 
                    <small>
                      <label for="MarketplaceSelect">
                        Select Marketplace
                      </label>
                    </small> */}
                    <select
                      class="form-control"
                      id="marketplace"
                      name="marketplace"
                    >
                      <option value="Amazon BR">Amazon BR</option>
                      <option value="Amazon CAN">Amazon CAN</option>
                      <option value="Amazon DE">Amazon DE</option>
                      <option value="Amazon ES">Amazon ES</option>
                      <option value="Amazon FR">Amazon FR</option>
                      <option value="Amazon MX">Amazon MX</option>
                      <option value="Amazon IN">Amazon IN</option>
                      <option value="Amazon IT">Amazon IT</option>
                      <option value="Amazon UAE" disabled>Amazon UAE</option>
                      <option value="Amazon UK">Amazon UK</option>
                      <option value="Amazon US" selected>Amazon US</option>
                      <option value="eBay" disabled>
                        eBay
                      </option>
                      <option value="Walmart">Walmart</option>
                    </select>
                    <input
                      type="hidden"
                      id="id"
                      name="id"
                      value={this.state.user.uid}
                    ></input>

                    <input
                      type="hidden"
                      id="email"
                      name="email"
                      value={this.state.user.email}
                    ></input>

                    <input
                      type="hidden"
                      id="name"
                      name="name"
                      value={user}
                    ></input>

                    <div className="text-center">
                      <Input
                        className="my-4"
                        type="file"
                        name="recfile"
                        placeholder="Select file"
                        accept=".csv,.xls,.xlsx"
                        required
                      />

                      <Button className="my-4" color="info" type="submit">
                        Upload
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
